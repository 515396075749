/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { Header, DateNew, MonthContainer } from './style';
import { Waiting } from '..';
import { findMonth } from '../../utils/methods';
import { getActivityDate } from '../../utils/methods';
import { fetchMonthlyRewardsPoint } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import LazyImage from '../common/LazyImage/LazyImage';
const Days = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat"
];

class NameHeader extends Component {
  constructor() {
    super();
    this.state = {
      month: '',
      hideContent: false
    };
  }

  componentDidMount() {
    // let currentMonth = moment().startOf("month").format('MMMM');
    // let currentYear = moment().startOf('year').format('YYYY');
    // let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
    // const { fetchMonthlyRewardsPoint } = this.props;
    // fetchMonthlyRewardsPoint(obj.startDate)
    // const d = new Date;
    // const month = findMonth(d.getMonth());
    // this.setState({
    //   month
    // });
  }

  render() {
    const { monthlyRewardPoint, totalPoints, themesHeader } = this.props;
    const d = new Date;
    const date = new Date();
    const day = date.getDay();
    if (isNull(monthlyRewardPoint) || isNull(totalPoints)) {
      return <Waiting />
    }
    const themeStyle = themesHeader?themesHeader:[];
    return (
      <Header bgColor={themeStyle && themeStyle['background_color'] || 0}>
        <DateNew>
          <p className="first">{Days[day]}</p>
          <p className="last">{new Date().getDate()}</p>
        </DateNew>
        <MonthContainer>
          <div>
            <p className="first">{themeStyle && themeStyle.month || ''} {themeStyle && themeStyle.year || ''}</p>
            <p className="last">{themeStyle && themeStyle.theme_text || ''}</p>
          </div>
          <div>
            <LazyImage src={`${ImageUrl}/${themeStyle && themeStyle.image_url}`} />
          </div>
        </MonthContainer>
      </Header>
    )
  }
}

NameHeader.propTypes = {
  history: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  fetchMonthlyRewardsPoint: PropTypes.func,
  monthlyRewardPoint: PropTypes.number,
  totalPoints: PropTypes.object,
  companyInfo: PropTypes.object,
  isAdmin: PropTypes.bool,
  role: PropTypes.bool,
  userCompany: PropTypes.object,
  themesHeader: PropTypes.array
};

const mapStateToProps = (state) => ({
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  totalPoints: state.wellnessDashboard.reward,
  companyInfo: state.companyDashboard.companyInfo,
  themesHeader: state.profileData.themesHeader
});

const mapDispatchToProps = (dispatch) => ({
  fetchMonthlyRewardsPoint: (startDate) => dispatch(fetchMonthlyRewardsPoint(startDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(NameHeader);
