/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import moment from 'moment';
import isNull from 'lodash/isNull';
import { HeaderMain, NameMain, ThemeBlockContainerMain} from './style';
import { Waiting } from '..';
// import { findMonth } from '../../utils/methods';
// import { getActivityDate } from '../../utils/methods';
import{ fetchMonthlyRewardsPoint } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';

class NameHeader extends Component {

  constructor() {
    super();
    this.state = {
      month: ''
    };
  }

  componentDidMount() {
    // let currentMonth =  moment().startOf("month").format('MMMM');
    // let currentYear = moment().startOf('year').format('YYYY');
    // let obj = getActivityDate(currentMonth.slice(0,3), currentYear);
    // const { fetchMonthlyRewardsPoint } = this.props;
    // fetchMonthlyRewardsPoint(obj.startDate)
    // const d = new Date;
    // const month = findMonth(d.getMonth());
    // this.setState({
    //   month
    // });
  }

  render() {
    const { monthlyRewardPoint, totalPoints, companyInfo, isAdmin, role, userCompany, userDetails, history, themesHeader} = this.props;
    if(isNull(monthlyRewardPoint) || isNull(totalPoints)) {
      return <Waiting />
    }
    const themeStyle = themesHeader?themesHeader:[];
    return (
      <HeaderMain bgColor={themeStyle && themeStyle['background_color'] || 0} >
        <NameMain isAdmin={isAdmin} role={role || ""} userCompany={userCompany}>
          Hello
          <span>
            {
              (history.location.pathname === '/portal') ? `${userDetails && userDetails.fname} ${userDetails && userDetails.lname}` :
                (isAdmin && companyInfo ? companyInfo.name :
                  role ? userCompany.name : null)
            }
          </span>
          <div className={companyInfo && isAdmin || role  ? 'block-level' : ""}>
            {
              (history.location.pathname === '/portal') ? '' : 'Admin Dashboard'
            }
          </div>
        </NameMain>
        <ThemeBlockContainerMain>
          {themeStyle&& themeStyle.length > 0 && <img src={`${ImageUrl}/${themeStyle.image_url || ''}`} />}
          <p>{themeStyle && themeStyle.theme_text || ''}</p>
        </ThemeBlockContainerMain>
      </HeaderMain>
    )
  }
}

NameHeader.propTypes = {
  history: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  fetchMonthlyRewardsPoint: PropTypes.func,
  monthlyRewardPoint: PropTypes.number,
  totalPoints: PropTypes.object,
  companyInfo: PropTypes.object,
  isAdmin: PropTypes.bool,
  role: PropTypes.bool,
  userCompany: PropTypes.object,
  themesHeader: PropTypes.array
};

const mapStateToProps = (state) => ({
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  totalPoints: state.wellnessDashboard.reward,
  companyInfo: state.companyDashboard.companyInfo,
  themesHeader: state.profileData.themesHeader
});

const mapDispatchToProps = (dispatch) => ({
  fetchMonthlyRewardsPoint: (startDate) => dispatch(fetchMonthlyRewardsPoint(startDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(NameHeader);