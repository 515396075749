
import styled from 'styled-components';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';
const Header = styled.div`
  width: 100%;
  max-width: 1246px;
  background-color: ${({bgColor}) => bgColor ? bgColor : '1C4667'};
  display: flex;
  margin: auto;
  border-radius: 3px;
  margin-top: ${({marginTop}) => marginTop ? '0px' : '25px'};
  margin-bottom: ${({marginBottom}) => marginBottom ? '25px' : ''};
  > div:first-child {
    width: 79px;
    display: block;
    border-radius: 3px;
  }

  > div:nth-child(2) {
    width: calc(100% - 115px);
    display: flex;
  }
`;

const DateNew = styled.div`
width: 79px;
height: 79px;
margin: 10px 25px 10px 10px;
background: white;
align-items: center;
.first{
  font-family: 'Rubik-Regular';
  font-size: 18px;
  line-height: 24px;
  color: #0D4270;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
}
.last{
  font-family: 'Rubik-Medium';
  font-size: 48px;
  line-height: 24px;
  color: #0D4270;
  text-align: center;
}
`;

const MonthContainer = styled.div`
display: flex;
> div:first-child {
  display: block;
  justify-content: flex-start;
  width: 60%;
.first{
  font-family: 'Rubik-Medium';
  font-size: 24px;
  line-height: 24px;
  color: #FFF;
  margin-top: 22px;
  margin-bottom: 8px;
}
.last{
  font-family: 'Rubik-Regular';
  font-size: 24px;
  line-height: 24px;
  color: #FFF;
  text-decoration: underline;
}
}
> div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  >div{
    width: 115px;
    height: 97px;
    width: auto;
    >img{
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
  }
}
`;

const Name = styled.div`
  color: white;
  padding: 0px 0 0 40px;
  display: block;
  float: left;
  height: 100%;
  align-items: center;
  font-family: Rubik-Light;
  font-size: 21px;
  line-height: 24px;
  > span {
    margin-left: 4px;
    font-family: Rubik-Medium;
    
  }
  
  > div {
    display: inline-block;
    opacity: 0.8;
    font-family: ${RegularFont};
    font-size: 22px;
  }
  
  .block-level {
    display: block
    font-family: ${MediumFont};
    font-size: 16px;
    opacity: 0.6;
  }
 
  @media (max-width: 950px) {
    padding: 0px 0 0 30px;
  }  
  @media (max-width: 590px) {
    width: 100%;
    text-align: center;
    padding: 10px 15px 10px 12px;
  }
  @media (max-width: 650px) {
    margin: 0 auto;
    padding-bottom: 5px;
    display: block;
    float: none;
    text-align: center;
  }
`;

const NewName = styled.div`

  color: white;
  //padding: 0px 15px 0 37px;
  display: flex;
  float: left;
  height: 100%;
  align-items: center;
  font-family: 'Rubik-Medium';
  //font-size: 21px;
  line-height: 24px;
  margin-right: 285px;
  position: relative;
  bottom: 4px;
  right: 33px;

  @media (max-width:1500px){
    bottom: 70px;
    right: 37px;
  }


  >p {
    font-size: 24px;
    margin-right: 12px;
  }

`;

const Points = styled.div`
  display: inline-block;
  float: right;
  padding-left: 10px;
  text-align: left;
  font-family: Rubik-Medium;

  > div:first-child {
    text-transform: uppercase;

    > span {
      text-transform: capitalize;
      font-family: Rubik-Light;
    }
  }

  > div:nth-child(2) {
    font-weight: 700;
    font-family: Rubik-Medium;
  }
`;

const ThemeBlockContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 48px;
  margin-left: -81px;
   position: relative;
   bottom: 39px;
 //margin-top: -25px;
 @media (max-width:1500px){
  bottom: 102px;
}
  > p {
    color: #fff;
    //font-family: ${BoldFont}
    font-family: Rubik;
    border-bottom: 1.5px solid white
    display: inline-block
    //font-size: 15px;
    font-size: 24px;
    //margin: 0 0 2px 9px;
    margin: 0 0 2px 44px;

    @media (max-width:400px) {
      padding-top: 5px;
    }  
  }
  > img {
    display: inline-block;
    width: 30px;
    height: auto;
    @media (max-width:400px) {
      margin: 0 auto;
    }
  }
  @media (max-width: 650px) {
    text-align: center;
    display: block;
    padding: 0;
    margin: 0 auto;
  }
  
  @media (max-width:400px) {
    display: block;
    width: 100%;
    padding: 0 10px;
  }
  .new_image {
    width: 100px
  }
`;


const DateContainer = styled.div`
    //width: 20%;
    width: 79px;
    height: 79px;
    background-color: #FFFFFF;
    border-radius: 3px;
    //transform: rotate(-180deg);
    margin-right: 12px;
    margin-left: -134px;
    text-align: center;
    //padding-top: 5px;
    padding: 10px;
    margin-bottom: -16px;
    .day_cls 
    {
      font-family: Rubik;
     font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #0D4270;
    margin-bottom: 10px;
    }
     .date_cls 
      {
        font-family: Rubik;
       font-style: normal;
      font-weight: 500;
       font-size: 48px;
       line-height: 24px;
       color: #0D4270;
      }

  > div {
    font-size: 14px;
    font-family: ${RegularFont}
  }
  > div: last-child {
    font-size: 30px;
    font-family: ${MediumFont}
  }

	@media (max-width: 766px){
		margin-left: 30px;
	}
`;

const ImageWrapper = styled.div`
width: 45px;
height: 45px;
padding: 5px;
//background-color: #f2f2f0;
display: flex;
justify-content: center;
border-radius: 4px;
margin-right: -617px;
border-radius: 4px;
//margin-top: -107px;
position: relative;
bottom: 78px;

@media (max-width: 1500px){
  bottom: 140px;
}

> img {
	margin-top: ${({margin}) => margin ? '5px' : ''};
	//width: 100%;
	//height: 100%;
  width: 115px;
  height: 88.34px;
 opacity: 0.4
}
`;

const HeaderMain = styled.div`
  width: 100%;
  float: right;
  padding: 20px 0px 20px 220px;
  background-color: ${({bgColor}) => bgColor ? bgColor : '1C4667'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 951px) {
    width: 100%;
    padding-left: 0px;
  }
  @media (max-width: 650px) {
    display: block;
  }
`;

const NameMain = styled.div`
  color: white;
  padding: 0px 0 0 40px;
  display: block;
  float: left;
  height: 100%;
  align-items: center;
  font-family: Rubik-Light;
  font-size: 21px;
  line-height: 24px;
  > span {
    margin-left: 4px;
    font-family: Rubik-Medium;
    
  }
  
  > div {
    display: inline-block;
    opacity: 0.8;
    font-family: ${RegularFont};
    font-size: 22px;
  }
  
  .block-level {
    display: block
    font-family: ${MediumFont};
    font-size: 16px;
    opacity: 0.6;
  }
 
  @media (max-width: 950px) {
    padding: 0px 0 0 30px;
  }  
  @media (max-width: 590px) {
    width: 100%;
    text-align: center;
    padding: 10px 15px 10px 12px;
  }
  @media (max-width: 650px) {
    margin: 0 auto;
    padding-bottom: 5px;
    display: block;
    float: none;
    text-align: center;
  }
`;

const PointsMain = styled.div`
  display: inline-block;
  float: right;
  padding-left: 10px;
  text-align: left;
  font-family: Rubik-Medium;

  > div:first-child {
    text-transform: uppercase;

    > span {
      text-transform: capitalize;
      font-family: Rubik-Light;
    }
  }

  > div:nth-child(2) {
    font-weight: 700;
    font-family: Rubik-Medium;
  }
`;

const ThemeBlockContainerMain = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 48px;
  > p {
    color: #fff;
    font-family: ${BoldFont}
    border-bottom: 1.5px solid white
    display: inline-block
    font-size: 15px;
    margin: 0 0 2px 9px;
    @media (max-width:400px) {
      padding-top: 5px;
    }  
  }
  > img {
    display: inline-block;
    width: 30px;
    height: auto;
    @media (max-width:400px) {
      margin: 0 auto;
    }
  }
  @media (max-width: 650px) {
    text-align: center;
    display: block;
    padding: 0;
    margin: 0 auto;
  }
  
  @media (max-width:400px) {
    display: block;
    width: 100%;
    padding: 0 10px;
  }
`;

const MonthContainerV2 = styled.div`
display: flex;
> div:first-child {
  display: block;
  justify-content: flex-start;
  width: 40%;
.first{
  font-family: 'Rubik-Medium';
  font-size: 24px;
  line-height: 24px;
  color: #FFF;
  margin-top: 22px;
  margin-bottom: 8px;
}
.one{
  font-family: 'Rubik-Medium';
  font-size: 24px;
  line-height: 24px;
  color: #FFF;
  margin-top: 17px;
  margin-bottom: 0px;
}
.last{
  font-family: 'Rubik-Regular';
  font-size: 24px;
  line-height: 24px;
  color: #FFF;
  text-decoration: underline;
}
.second{
  font-family: 'Rubik-Regular';
  font-size: 24px;
  line-height: 18px;
  color: #FFF;
  text-decoration: underline;
  margin-top: 15px;
}
}
> div:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  padding-right: 25px;
  >div{
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: flex-end;
    >div{
      // width: calc(100% / 3);
      margin-left: 10.5%;
      display: block;
      &:first-child{margin-left:0px};
      > div:nth-child(1){
        width: 100%;


        font-family: Rubik;
        font-size: 16px;
        line-height: 20px;
        color:#FFFFFF;
        margin-bottom: 10px;
        @media (max-width: 650px) {
          font-size: 14px;
        }  
      }
      > div:nth-child(2){
        width: 100%;

        font-family: 'Rubik-Bold';
        font-size: 16px;
        line-height: 20px;
        color:#FFFFFF;
        @media (max-width: 650px) {
          font-size: 14px;
        } 
      }
    }
  }
}
`;

export { Header, Name, Points, ThemeBlockContainer,NewName,DateContainer,ImageWrapper, HeaderMain, NameMain, PointsMain, ThemeBlockContainerMain, DateNew, MonthContainer, MonthContainerV2};
