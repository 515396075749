/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { Header, DateNew, MonthContainerV2 } from './style';
import { findMonth } from '../../utils/methods';
import { getActivityDate, rankingDashboard, commaSeperator } from '../../utils/methods';
import { fetchMonthlyRewardsPoint, getCompanyRank } from '../../redux/actions';
// import { prototype } from 'react-bootstrap/lib/Accordion';
const Days = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat"
];
import { withTranslation } from 'react-i18next';
import SkeletonLoder from '../common/skeletonLoder';

class LeaderbordNameHeader extends Component {
  constructor() {
    super();
    this.state = {
      month: '',
      hideContent: false
    };
  }

  componentDidMount() {
    let currentMonth = moment().startOf("month").format('MMMM');
    let currentYear = moment().startOf('year').format('YYYY');
    let obj = getActivityDate(currentMonth.slice(0, 3), currentYear);
    const { fetchMonthlyRewardsPoint, companyId,getCompanyRank } = this.props;
    fetchMonthlyRewardsPoint(obj.startDate)
    getCompanyRank(companyId)
    const d = new Date;
    const month = findMonth(d.getMonth());
    this.setState({
      month
    });
  }

  render() {
    const { monthlyRewardPoint, totalPoints, themesHeader, userId, userRank, t, userTotalPoints } = this.props;
    const d = new Date;
    const date = new Date();
    const day = date.getDay();
    if (isNull(monthlyRewardPoint) || isNull(totalPoints) || isNull(userRank) || isUndefined(userRank)) {
      return <SkeletonLoder width={"1246px"} height={"99px"} style={{margin:'auto', marginBottom:'25px'}}/>
    }
    const themeStyle = themesHeader?themesHeader:[];
    const lang = localStorage.getItem("lang");
    return (
      <Header bgColor={themeStyle && themeStyle['background_color'] || 0} marginTop="1" marginBottom="1">
        <DateNew>
          <p className="first">{t(Days[day])}</p>
          <p className="last">{new Date().getDate()}</p>
        </DateNew>
        <MonthContainerV2 text="1">
          <div>
            <p className="one">{themeStyle && t(themeStyle.month) || ''} | {themeStyle && themeStyle.year || ''}</p>
            <p className="second">{themeStyle && t(themeStyle.theme_text) || ''}</p>
          </div>
          <div>
            <div>
              <div>
                { lang != "fr" ? <div>{themeStyle && this.props.t(themeStyle.month) || ''} {t("Points")}</div> : <div> {t("Points") + " en "} {themeStyle && this.props.t(themeStyle.month) || ''}</div> }
                <div>{commaSeperator(monthlyRewardPoint)} {t("Pts")}</div>
              </div>
              <div>
                <div>{t("My Total Points")}</div>
                <div>{totalPoints.total ? `${commaSeperator(userTotalPoints?.my_points)} ${t("Pts")}` : ''}</div>
              </div>
              <div>
                <div>{t("My Company Rank")}</div>
                <div>{!isUndefined(userRank.user_rank)?userRank.user_rank:"0"}</div>
              </div>
            </div>
          </div>
        </MonthContainerV2>
      </Header>
    )
  }
}

LeaderbordNameHeader.propTypes = {
  history: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  fetchMonthlyRewardsPoint: PropTypes.func,
  monthlyRewardPoint: PropTypes.number,
  totalPoints: PropTypes.object,
  companyInfo: PropTypes.object,
  isAdmin: PropTypes.bool,
  role: PropTypes.bool,
  userCompany: PropTypes.object,
  themesHeader: PropTypes.array,
  userId: PropTypes.number,
  topUsersTotalPointsAll: PropTypes.object,
  companyId: PropTypes.number,
  getCompanyRank: PropTypes.func,
  userRank: PropTypes.array,
  t: PropTypes.func,
  userTotalPoints: PropTypes.object
};

const mapStateToProps = (state) => ({
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  totalPoints: state.wellnessDashboard.reward,
  companyInfo: state.companyDashboard.companyInfo,
  themesHeader: state.profileData.themesHeader,
  topUsersTotalPointsAll: state.companyDashboard.topUsersTotalPointsAll,
  userRank: state.companyDashboard.userRank,
  userId: state.profileData.userId,
  userTotalPoints: state.companyDashboard.userTotalPoints
});

const mapDispatchToProps = (dispatch) => ({
  fetchMonthlyRewardsPoint: (startDate) => dispatch(fetchMonthlyRewardsPoint(startDate)),
  getCompanyRank: (id) => dispatch(getCompanyRank(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeaderbordNameHeader));
